import { render, staticRenderFns } from "./EditGideonCardPhone.vue?vue&type=template&id=4ed8e124&scoped=true&"
import script from "./EditGideonCardPhone.vue?vue&type=script&lang=js&"
export * from "./EditGideonCardPhone.vue?vue&type=script&lang=js&"
import style0 from "./EditGideonCardPhone.vue?vue&type=style&index=0&id=4ed8e124&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ed8e124",
  null
  
)

export default component.exports